import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { PrimaryCarousel } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontFamily, FontSize,
} from '@powdr/constants';
import { themeUtil, rgbaTransparencyToHex, getFontStyles } from '@powdr/utils';

const CPNT = Components.IMAGE_GRID;

export const StyledImageGrid = styled.div`
  position: relative;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  //padding: 0 7.5%;
  max-height: 850px;

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;

const SharedCarouselStyles = css`
  height: calc(100vh - 65px);
  padding-bottom: 65px;

  ${Breakpoints.mobile(css`
    padding-bottom: 25px;
  `)}

  .image-grid-pagination {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
  }

  .image-grid-control-prev, .image-grid-control-next {
    position: absolute;
    top: 50%;
    width: 35px;

    .control-arrow-inner {
      position: relative;

      :after {
        content: " ";
        position: absolute;
        top: calc(50% - 35px);
        border: solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
        border-width: 35px;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        width: 0;
        height: 0;
        z-index: 2;
        border-right: none;
      }

      .control-arrow-icon {
        position: relative;
        z-index: 3;

        svg {
          fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
        }
      }
    }

    &:hover, &:focus {
      .control-arrow-inner {
        :after {
          border: solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
          border-width: 35px;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          border-right: none;
        }

        .control-arrow-icon {
          svg {
            fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
            color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
          }
        }
      }
    }
  }

  .image-grid-control-prev {
    left: 0;

    .control-arrow-inner {

      .control-arrow-icon {
        position: relative;
        z-index: 3;
        left: 5px;
      }

      :after {
        left: 0;
      }
    }
  }

  .image-grid-control-next {
    right: 0;

    .control-arrow-inner {

      .control-arrow-icon {
        left: 5px;
      }

      :after {
        right: 0;
      }
    }
  }

  .primary-carousel-thumbnails {
    height: auto;
    margin: 0 7.5%;
    width: auto;
    padding-top: 25px !important;

    ${Breakpoints.mobile(css`
      margin: 0 3.5%;
    `)}
  }

`;

export const MobileCarousel = styled(PrimaryCarousel)`
  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}

  ${SharedCarouselStyles}
`;

export const ImageContainer = styled.div`
  position: relative;
  min-width: 0;
  min-height: 0;

  &.large-image { grid-area: 1 / 1 / 3 / 2; }
  &.small-image-1 { grid-area: 1 / 2 / 2 / 3; }
  &.small-image-2 { grid-area: 1 / 3 / 2 / 4; }
  &.small-image-3 { grid-area: 2 / 2 / 3 / 3; }
  &.small-image-4 { grid-area: 2 / 3 / 3 / 4; }

  &:hover {
    .image-overlay {
      transition: all 0.25s ease-in-out;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${() => `#000000${rgbaTransparencyToHex(0.15)}`};
  opacity: 0;
`;

export const Image = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 100%;
  grid-area: 1/1;
`;

export const ImageCarousel = styled(PrimaryCarousel)`
  ${SharedCarouselStyles}
`;

export const ImageAlignmentWrapper = styled.div`
  display: grid;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ViewAllButton = styled.button`
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined)};
  font-weight: bold;
  padding: 0;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  padding: 12px 16px;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  width: 175px;

  &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    display: none;
  `)}
`;
