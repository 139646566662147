import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ImageAttribution, Modal } from '@powdr/components';
import {
  CarouselControlPlacement,
  CarouselPaginationType,
  CarouselTransitionEffects,
  NavigationDirection,
} from '@powdr/constants';
import { getImageAltText } from '@powdr/utils';

import {
  StyledImageGrid, GridContainer, Image,
  ImageContainer, ImageOverlay, ViewAllButton,
  ImageCarousel, ImageAlignmentWrapper, MobileCarousel,
} from './styles';

export const ImageGrid = ({
  className,
  relationships,
  parentColorProfile,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const images = relationships?.images;

  const CarouselImageMap = () => images.map((image) => (
    <ImageAlignmentWrapper colorProfile={parentColorProfile}>
      <Image alt={getImageAltText(image.relationships.image)} image={image.relationships.image.gatsbyImage} objectFit="contain" />
      <ImageAttribution>
        {image?.relationships?.image?.relationships?.media__image?.[0]?.attribution}
      </ImageAttribution>
    </ImageAlignmentWrapper>
  ));

  return (
    <StyledImageGrid className={className}>
      {/* Desktop Grid */}
      <GridContainer>
        {images.map((image, idx) => (
          (idx < 5) && (
            <ImageContainer className={(idx === 0) ? 'large-image' : `small-image-${idx}`} onClick={() => setIsModalOpen(true)}>
              <ImageOverlay className="image-overlay" />
              <Image
                alt={getImageAltText(image.relationships.image)}
                image={image.relationships.image.gatsbyImage}
              />
            </ImageContainer>
          )))}
      </GridContainer>

      {/* Mobile Carousel */}
      <MobileCarousel
        controlSettings={{
          navigation: {
            placement: CarouselControlPlacement.ON_SLIDE,
            iconName: 'ui-arrow-left',
            iconSize: 15,
            classes: {
              [NavigationDirection.PREVIOUS]: 'image-grid-control-prev',
              [NavigationDirection.NEXT]: 'image-grid-control-next',
            },
          },
          pagination: {
            placement: CarouselControlPlacement.ON_SLIDE,
            type: CarouselPaginationType.FRACTION,
            class: 'image-grid-pagination',
          },
        }}
        isAutoHeight
        colorProfile={parentColorProfile}
      >
        {CarouselImageMap()}
      </MobileCarousel>

      {/* Desktop Modal Button */}
      <ViewAllButton onClick={() => setIsModalOpen(true)} colorProfile={parentColorProfile}>
        View All Images
      </ViewAllButton>

      {/* Carousel Modal */}
      {(isModalOpen) && (
        <Modal
          handleCloseModal={() => { setIsModalOpen(false); }}
          isFullScreen
          colorProfile={parentColorProfile}
          zIndexAdjust={205}
          removeScroller
        >
          <ImageCarousel
            controlSettings={{
              navigation: {
                placement: CarouselControlPlacement.ON_SLIDE,
                iconName: 'ui-arrow-left',
                iconSize: 15,
                classes: {
                  [NavigationDirection.PREVIOUS]: 'image-grid-control-prev',
                  [NavigationDirection.NEXT]: 'image-grid-control-next',
                },
              },
              pagination: {
                placement: CarouselControlPlacement.ON_SLIDE,
                type: CarouselPaginationType.FRACTION,
                class: 'image-grid-pagination',
              },
              thumbnails: {
                breakpoints: {
                  320: {
                    slidesPerView: Math.min(3, images?.length),
                  },
                  960: {
                    slidesPerView: Math.min(5, images?.length),
                  },
                  1600: {
                    slidesPerView: Math.min(7, images?.length),
                  },
                  1920: {
                    slidesPerView: Math.min(9, images?.length),
                  },
                },
              },
            }}
            isShowThumbnails
            transitionEffect={CarouselTransitionEffects.FADE}
            colorProfile={parentColorProfile}
          >
            {CarouselImageMap()}
          </ImageCarousel>
        </Modal>
      )}
    </StyledImageGrid>
  );
};

ImageGrid.propTypes = {
  className: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

ImageGrid.defaultProps = {
  className: '',
};
